import { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { geoFindMe } from "./gateway/Functions";
import { Button, Result } from "antd";

function App() {
  // const loggedIn = useSelector((state: any) => state.LoggedIn);
  const dispatch = useDispatch();
  const { refreshToken } = useSelector((state: any) => state.AccessToken);

  useEffect(() => {
    // geoFindMe(dispatch);
    localStorage.clear();
  }, [dispatch, refreshToken]);
  return (
    // <DndProvider backend={HTML5Backend}>
    //   <Flex
    //     height="100vh"
    //     width="100vw"
    //     backgroundColor={primaryGray}
    //     // filter={"auto"}
    //     // invert={"90%"}
    //   >
    //     <ShowAlert />
    //     <Router>
    //       <Suspense fallback={<Loader fullScreen />}>
    //         <Routes>
    //           {!loggedIn && (
    //             <>
    //               {authRoutes.map((route, index) => (
    //                 <Route
    //                   key={route.path}
    //                   path={route.path}
    //                   element={route.component}
    //                 />
    //               ))}
    //               <Route path="*" element={<Navigate to={"/login"} />} />
    //             </>
    //           )}
    //         </Routes>
    //       </Suspense>
    //       {/* The stripe checkout routes should not load in a logged-in layout */}
    //       {window.location.href.includes("/stripe/checkout-") && (
    //         <Routes>
    //           <Route
    //             path="/stripe/checkout-success"
    //             element={<StripeCheckoutSuccess />}
    //           />
    //           <Route
    //             path="/stripe/checkout-cancelled"
    //             element={<StripeCheckoutCancelled />}
    //           />
    //         </Routes>
    //       )}
    //       {/* The stripe checkout routes should not load in a logged-in layout */}
    //       {loggedIn && !window.location.href.includes("/stripe/checkout-") && (
    //         <SocketIoContextProvider url={websocketUrl ?? ""}>
    //           <BongaLayout />
    //         </SocketIoContextProvider>
    //       )}
    //     </Router>
    //   </Flex>
    // </DndProvider>
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button disabled type="primary">
          Please contact your system administrator!!
        </Button>
      }
    />
  );
}

export default App;
